<template>
  <div id="app">   
    <div class="menu" v-if="!portfolio"
      @mouseover="showMenu" @mouseleave="hideMenu" :class="{ 'open': menu, 'locked': content, 'project': project }">
      <div class="menu-box" v-if="apiContent.colors" :style="[ !project ? {'background-color': apiContent.colors.menuLight.hex} : {'background-color': apiContent.colors.menuDark.hex}]">
        <div class="menu-links">
          <router-link to="/info">
            Info
            <span v-if="apiContent.colors" :class="[{ active: $route.path === '/info' }, 'underline']" :style="'background-color:' + apiContent.colors.colorInfo.hex + ';'"></span>
          </router-link>
          <router-link to="/overview">
            Overview
            <span v-if="apiContent.colors" :class="[{ active: $route.path === '/overview' }, 'underline']" :style="'background-color:' + apiContent.colors.colorOverview.hex + ';'"></span>
          </router-link>
          <router-link v-if="project" to="">
            Project
            <span v-if="apiContent.colors" :class="[{ active: $route.path.indexOf('project') }, 'underline']" :style="projectLine ? 'background-color:' + projectLine + ';' : 'background-color:' + apiContent.colors.colorProject.hex + ';'"></span>
          </router-link>
          <a v-else>&nbsp;</a>
        </div>
        <div class="menu-close" v-if="content" @click="backHome">X</div>
        <p>{{ apiContent.websiteTitle }}</p>
      </div>
    </div>
    <div class="tm" @click="menu = true">
      <div></div>
      <router-link class="tm-desk" to="/">
        TM        
      </router-link> 
      <div class="tm-mobile">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <router-view :is="Component" :apiContent=apiContent >
    </router-view>
  </div>
</template>

<script>
// @ is an alias to /src
import sanity from "./client";
// import imageUrlBuilder from "@sanity/image-url";
// const imageBuilder = imageUrlBuilder(sanity);
const query = `*[_type == "site"]`;

export default {
  name: "Home",
  data() {
    return {
      apiContent: [],
      menu: false,
      desktop: false,
      bgAngle: '90deg',
      content: false,
      project: false,
      portfolio: false,
      y: 0,
      colors: {
        menuBg: '',
        darkmenuBg: '',
        infoLine: '',
        oveLine: '',
        projLine: '',
      }
    };
  },
  computed: {
    projectLine() {
      const app = this
      let i = 0;
      let color = '';
      let projects = app.apiContent.projects

      while (i < projects.length) {
        if (projects[i].projectBgColor && projects[i].projectTitle.replace(/ +/g, '-').toLowerCase() === app.$route.params.title) {
          color = projects[i].projectBgColor.hex;
        } 
        i++
      }
      return color
    }
  },
  methods: {
    fetchData() {
      sanity.fetch(query).then(
        (content) => {
          this.apiContent = content[0]
        },
        (error) => {
          this.error = error;
        }
      );
    },
    resizeBrowserHandler (e) {
        // mobile screen
        if (e.target.innerWidth < 768) {
            this.bgAngle = '180deg'
        } else {
            this.bgAngle = '90deg'
        }
    },
    handleScroll () {
      if (window.innerWidth < 768) {
          this.menu = false
      }
    },
    showMenu () {
      if (!this.content) {
        this.menu = true
      }
    },
    hideMenu () {
      if (!this.content) {
        this.menu = false
      }
    },
    backHome () {
      this.content = false
      this.$router.push('/')
    },
    hyphenation(str) {
        return str.replace(/ +/g, '-').toLowerCase();
    }, 
  },
  mounted() {
    if (['/info', '/overview'].includes(this.$route.path)) {
      this.content = true
      this.project = false
      this.portfolio = false
    } else if (this.$route.path.indexOf('/project/') != -1) {
      this.content = true
      this.project = true
      this.portfolio = false
    } else if (this.$route.path.indexOf('/portfolio/') != -1) {
      this.content = false
      this.project = false
      this.portfolio = true
    } else {
      this.portfolio = false
      this.content = false
      this.project = false
    }
  },
  created() {
    window.addEventListener("resize", this.resizeBrowserHandler);
    window.addEventListener('scroll', this.handleScroll);
    this.fetchData();
  },
  unmounted() {
      window.removeEventListener("resize", this.resizeBrowserHandler);
      window.removeEventListener('scroll', this.handleScroll);
  },
  watch:{
    $route (){
      if (['/info', '/overview'].includes(this.$route.path)) {
        this.content = true
        this.project = false
        this.portfolio = false
      } else if (this.$route.path.indexOf('/project/') != -1) {
        this.content = true
        this.project = true
        this.portfolio = false
      } else if (this.$route.path.indexOf('/portfolio/') != -1) {
        this.content = false
        this.project = false
        this.portfolio = true
      } else {
        this.portfolio = false
        this.content = false
        this.project = false
      }
    }
  },
};
</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,200;0,300;1,100;1,200;1,300&display=swap');

body {
  margin: 0;
  color: #7C7C7C;
  font-family: 'Work Sans', sans-serif;
  font-weight: 300;
  a, p {
    color: #7C7C7C;
  }
}

.menu {
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
  width: 15%;
  min-width: 150px;
  z-index: 1;
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 180px;
    p {
      display: none;
    }
  }

  &.open, &.locked {
    z-index: 5;
    .menu-box {
      transform: translate(0);
    }
  }

  &.project {
    .menu-close {
      margin-top: -32px;
    }
  }

  &-box {
    padding: 0px 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 40px;
    transform: translate(-100%, 0);
    transition: ease-in-out 0.3s;
    width: 100%;
    min-width: 150px;
    max-width: 12vw;
  @media only screen and (max-width: 768px) {
      transform: translate(0, -100%);
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0px 30px 0 30px;
      max-width: 100%;
    }
  }
  &-close {
    cursor: pointer;
    @media only screen and (max-width: 768px) {
      font-size: 40px;      
      margin-right: 14px;
    }
  }
  a {
    display: table;
    font-size: 18px;
    line-height: 1;
    text-decoration: none;
    margin-bottom: 12px;
    .underline {
      display: block;
      height: 0px;
      width: 100%;
      margin-bottom: 2px;
      &.active {
        height: 2px;
        margin-bottom: 0px;
      }
    }
    @media only screen and (max-width: 768px) {
      font-size: 30px;
    }
  }
}
.tm {
  position: fixed;
  z-index: 2;
  left: 30px;
  top: 30px;
  &-desk {
    font-size: 50px;
    line-height: 1;
    font-weight: 100;
    color: black;
    letter-spacing: 4px;
    text-decoration: none;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  &-mobile {
    display: none;
    @media only screen and (max-width: 768px) {
      display: block;
    }
    span {
      display: block;
      width: 50px;
      height: 2px;
      background: black;
      margin-bottom: 10px;
    }
  }
}

.content {
  position: absolute;
  left: 12%;
  top: 0;
  width: 88%;
  min-height: 100%;
  @media only screen and (max-width: 1280px) {
    left: 150px;
    width: calc(100% - 150px);
  }
  @media only screen and (max-width: 768px) {
    left: 0;
    top: 180px;
    width: 100%;
    min-height: calc(100vh - 180px);
  }

}


</style>
